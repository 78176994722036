<template>
  <loader v-if="loader" />
  <div class="pass-generator-container">
    <div class="report-container">
      <!-- <p>Object : {{ data }}</p>
      <p>Device : {{ get_device }}</p>
      <p>Location : {{ this.location }}</p> -->
      <h3 class="error" v-if="error">
        {{ error }} <a href="#" @click="retry">Try again</a>
      </h3>
      <!-- <h3 class="success" v-else>
        Device Detected {{ osName }}. Please Wait...
      </h3> -->
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/service";
import { useRoute } from "vue-router";
import DeviceDetector from "device-detector-js";
import loader from "../common/AppLoader/AppLoader.vue";
import { configuration } from "@/configurationProvider";
import axios from "axios";
import { auth } from "@/services/auth.service";
import { saveAs } from "file-saver";
// var FileSaver = require("file-saver");

export default {
  name: "PassGenerator",
  components: {
    loader,
  },
  props: {
    clubId: {
      type: String,
    },
    templateId: {
      type: String,
    },
    memberId: {
      type: String,
    },
  },
  data() {
    return {
      data: {},
      loader: false,
      error: "",
      get_device: "",
      location: "",
    };
  },
  computed: {
    osName() {
      if (Object.keys(this.data).length) {
        return this.data.os.name;
      }
      return "";
    },
  },

  mounted() {
    this.validation();
  },
  watch: {},
  methods: {
    retry() {
      window.location.reload();
    },
    timeStamps() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      // const min = date.getMinutes();
      // const sec = date.getSeconds();
      return `${year}${month}${day}${hour}`;
    },
    async validation() {
      this.loader = true;
      let device = "google";
      const route = useRoute();
      const deviceDetector = new DeviceDetector();
      this.data = deviceDetector.parse(navigator.userAgent);
      if (this.data.os.name != undefined) {
        if (this.data.os.name === "iOS" || this.data.os.name === "Mac") {
          this.get_device = "apple";
          device = "apple";
        } else {
          this.get_device = "google";
          device = "google";
        }

        let payload = {
          key: route.query.key,
          device: device,
        };

        // ;

        let token = null;

        if (configuration.enableIdentityServer) {
          token = await auth.getAccessToken();
        } else {
          token = localStorage.getItem("clubeez_session");
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        if (route.query.entityType) {
          if (device === "apple") {
            axios({
              url: `${configuration.apiBaseUrl}/api/v1/vouchers/generate/`,
              method: "POST",
              responseType: "blob",
              headers: headers,
              data: payload,
            })
              .then((response) => {
                this.loader = false;
                // ;
                if (response.data != null) {
                  saveAs(response.data, `${this.timeStamps()}.pkpass`);

                  // let fileURL = window.URL.createObjectURL(
                  //   new Blob([response.data])
                  // );
                  // let fileLink = document.createElement("a");
                  // fileLink.href = fileURL;
                  // let filename = `${this.timeStamps()}.pkpass`;
                  // fileLink.setAttribute("download", filename);
                  // document.body.appendChild(fileLink);
                  // fileLink.click();
                } else {
                  this.error = "Failed to generate card";
                }
              })
              .catch((error) => {
                this.loader = false;
                this.error = "Failed to generate card!";
              });
          } else if (device === "google") {
            axios({
              url: `${configuration.apiBaseUrl}/api/v1/vouchers/generate/`,
              method: "POST",
              headers: headers,
              data: payload,
            })
              .then((response) => {
                this.loader = false;
                if (device === "google") {
                  this.location = response.data.googlePassUrl;
                  if (response.data.googlePassUrl != null) {
                    window.location.href = response.data.googlePassUrl;
                  } else {
                    this.error = "Failed to generate card.";
                  }
                }
              })
              .catch((error) => {
                this.loader = false;
                ;
                this.error = "Failed to generate card.";
              });
          }
        } else {
          HTTP.post(
            `${configuration.apiBaseUrl}/api/v1/pass/generate/`,
            payload
          )
            .then((response) => {
              this.loader = false;
              ;
              if (response.status === 200) {
                if (device === "apple") {
                  this.location = response.data.data.applePassUrl;
                  if (response.data.data.applePassUrl != null) {
                    window.location.href = response.data.data.applePassUrl;
                  } else {
                    this.error = "Failed to generate card.";
                  }
                } else {
                  this.location = response.data.data.googlePassUrl;
                  if (response.data.data.googlePassUrl != null) {
                    window.location.href = response.data.data.googlePassUrl;
                  } else {
                    this.error = "Failed to generate card.";
                  }
                }
              } else {
                this.loader = false;
                this.error = "Failed to generate card.";
              }
            })
            .catch((error) => {
              this.loader = false;
              ;
              this.error = "Failed to generate card.";
            });
        }
      } else {
        this.loader = false;
        this.error = "Can't detect device";
      }
    },
  },
};
</script>

<style scoped>
.report-container {
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.success {
  font-size: 50px;
  color: green;
  line-height: 56px;
  padding: 15px;
}

.error {
  color: red;
  font-size: 50px;
  line-height: 56px;
  padding: 15px;
}
</style>
